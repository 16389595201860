import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.section`
  background-color: rgba(240, 241, 241, 1);
  z-index: 0;
  display: flex;
  min-height: 720px;
 
  flex-direction: column;
  color: rgba(13, 74, 100, 1);
  justify-content: center;
  padding: 0px 200px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  text-transform:captalize;
  font: 700 34px/62px Open Sans, sans-serif;
`;

const Description = styled.p`
width:50%;
  margin-top: 32px;
  font: 400 24px/40px Inter, sans-serif;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

function VisionSection() {
  
  const [content, setContent] = useState({ title: '', body: '' }); // Use state to store content text

useEffect(() => {
  fetch(`${process.env.REACT_APP_API_BASE_URL}/vission-mission`)
    .then(response => response.json())
    .then(result => {
      if (result && result.data && result.data.attributes) {
        setContent({
          title: result.data.attributes.title || 'Default Title', // Set title from API
          body: result.data.attributes.content || 'No content available.', // Set content from API
        }); // Set the content text
      } else {
        setContent({ title: 'No title available', body: 'No content available.' }); // Handle case with no data
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setContent({ title: 'Failed to load title', body: 'Failed to load content' }); // Set error message
    });
}, []);

  return (
    <SectionWrapper id="vision">
      <Content>
        
        <Title>{content.title}</Title>
        
        <Description>
        {content.body || 'Loading...'}
        </Description>
      </Content>
    </SectionWrapper>
  );
}

export default VisionSection;