import React from "react";
import styled from "styled-components";
import TeamMemberCard from "./TeamMemberCard";

const teamMembers = [
  {
    name: "Gil Tadmor",
    role: "Founder & Managing Principal",
    imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/0000766d71f03e12284b9ddfb737bdfa856866a163fbbfec92ca360f01ad4c21?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66",
    linkedInSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/a08dda4c12c38518a76d62ce57b1393ea37921826111e7ca36f1af7d600e66dd?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66",
    linkedInSrc2:"https://www.linkedin.com/in/gtadmor/"
  },
  {
    name: "Dheeraj Batra",
    role: "Advisor",
    imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/74b26672caa5613501d1be10ad483ab728976692e88c7818e0936738be12b33d?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66",
    linkedInSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/a08dda4c12c38518a76d62ce57b1393ea37921826111e7ca36f1af7d600e66dd?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66",
 linkedInSrc2:"https://www.linkedin.com/in/dheerajbatra/"
  },
  {
    name: "Vishnu Rachakonda",
    role: "Entrepreneur-In Residence",
    imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb0a20dc663786489208f2756ed81f55cbd541f398389db312e96fc82248ec28?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66",
    linkedInSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/a08dda4c12c38518a76d62ce57b1393ea37921826111e7ca36f1af7d600e66dd?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66",
    linkedInSrc2:"https://www.linkedin.com/in/vrachakonda/"
  }
];

function TeamSection() {
  return (
    <StyledTeamSection>
      <h2 className="team-title">Our Team</h2>
      <BackgroundWrapper />
      <div className="team-members">
      
        {teamMembers.map((member, index) => (
          <TeamMemberCard key={index} {...member} />
        ))}
      </div>
    </StyledTeamSection>
  );
}

const StyledTeamSection = styled.section`
  background-color: #fff;
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  justify-content: center;
  position: relative; 

  .team-title {
    color: #6d523a;
    font-size: 34px;
    font-weight: 700;
    line-height: 1;
    text-transform: capitalize;
 
   margin-left: 70px;
    margin-bottom: 40px;

    @media (max-width: 991px) {
      max-width: 100%;
      margin-bottom: 40px;
    }
  }

  .team-members {
   
    display: flex;
     z-index: 1; /* Keep team members above the background */
  
    width: 100%;
    padding-bottom: 40px;
    align-items: flex-end;
    gap: 40px 120px;
    color: #f0f1f1;
 
    justify-content: center;
    flex-wrap: wrap;
    

    @media (max-width: 800px) {
      max-width: 100%;
       background-color: #0d4a64;
    }
  }
`;
const BackgroundWrapper = styled.div`
  position: absolute;
     top: 300px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0d4a64;
  z-index: 0; /* Ensure this background is behind the content */
    @media (max-width: 768px) {
    display: none; /* Hide the background on mobile devices */
  }
`;

export default TeamSection;