


import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from './Components/MainLayout';

import Layout from './SecondPage/Layout';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />} />

        <Route path="/form" element={<Layout />} />
      </Routes>
    </Router>
  );
}

export default App;


