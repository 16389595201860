import React from 'react';
import styled from 'styled-components';

const HeroWrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 520px;

  padding-left: 104px;
  align-items: flex-start;
  font-weight: 400;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-left: 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  width: 526px;
  max-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

const Heading = styled.h1`
  display: flex;
  width: 100%;
  flex-direction: column;
  letter-spacing: 1.2px;
  justify-content: flex-start;
  font: 40px Poppins, sans-serif;
  margin-bottom: 18px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeadingPart = styled.span`
  color: ${props => props.color};
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Description = styled.p`
  color: #fff;
  letter-spacing: 0.69px;
  margin-top: 18px;
  font: 23px/30px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeroSection = () => (
  <HeroWrapper>
    <BackgroundImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f6417bb02bdf6b83f5cc8ca2eedb54ac7ea1872d9667d6d948b8a17aa58f370?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="Hero background" />
    <ContentWrapper>
      <Heading>
        <HeadingPart color="#fff">We empower tomorrow’s</HeadingPart>
        <HeadingPart color="#91c46b">innovators </HeadingPart>
      </Heading>
      <Description>
      Pitch your ideas, connect with investors, and bring your startup dreams to life. 
      </Description>
    </ContentWrapper>
  </HeroWrapper>
);

export default HeroSection;