import React from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const handlePitchClick = () => {
    navigate('/form');
  };
  return (
    <header className="header">
      <div className="header-content">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/95fef97434272419a2b97f11299d0bb507b56369a60d5257ee5cb8d450dfe1ea?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="Background" className="header-background" />
        <div className="header-text">
          <h1 className="header-title">Fueling Innovation.</h1>
          <h2 className="header-subtitle">Backing Disruptive Startups</h2>
          <button className="cta-button" onClick={handlePitchClick}>PITCH TO US</button>
        </div>
      </div>
      <div className="logo-nav-container">
        <Logo />
        <Navigation />
      </div>
    </header>
  );
};

export default Header;